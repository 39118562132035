'use strict';

import Glide, { Controls, Swipe, Breakpoints, Images } from '@glidejs/glide/dist/glide.modular.esm'

/**
 * Validates and Return the cquotient namespace provided by the commerce cloud platform
 * @returns {Object} - einsteinUtils or null
 */
function getEinsteinUtils() {
    var einsteinUtils = window.CQuotient;
    if (einsteinUtils && (typeof einsteinUtils.getCQUserId === 'function') && (typeof einsteinUtils.getCQCookieId === 'function')) {
        return einsteinUtils;
    }
    return null;
}

/**
 * Creates carousel bullet items
 * @param {jQuery} $parentEl container element
 */
function createControls($parentEl) {
    const $carouselItems = $($parentEl).find('.glide__slide').not('.glide__slide--clone');
    let $carouselBullets = $($parentEl).find('[data-glide-el="controls[nav]"]');
    $carouselItems.each(function (index) {
        var $newIndiator = $(`<button class="glide__bullet" data-glide-dir="=${index}"></button>`);
        $carouselBullets.append($newIndiator);
    });
    $carouselBullets.removeClass('d-none');
}

/**
 * Initialize einstein carousel
 * @param {jQuery} $containerEl the wrapper element
 */
function initializeCarousel($containerEl) {
    const $carousel = $($containerEl);
    const $carouselItems = $($carousel).find('.glide__slide').not('.glide__slide--clone');
    const $controls = $($carousel).find('[data-glide-el="controls"]');
    const totalSlides = $carouselItems.length;

    let glide;
    const defaults = {
        type: 'slider',
        perView: 4,
        rewind: false,
        bound: true,
        gap: 24,
        breakpoints: {
            1024: {
                rewind: true,
                perView: 3,
                gap: 16
            },
            768: {
                rewind: true,
                perView: 2,
                gap: 16
            }
        }
    };

    const handleUpdateEvent = () => {
        if (!glide) { return; }
        const { perView } = glide.settings;
        if (totalSlides <= perView) {
            $controls.addClass('d-none');
        } else {
            $controls.removeClass('d-none');
            $($carousel).closest('.product-recommendations-carousel').addClass('active-glide');
        }
    }

    glide = new Glide($carousel.get(0), defaults)
        .on('build.before', handleUpdateEvent)
        .mount({
            Controls,
            Breakpoints,
            Swipe,
            Images
        });
    
    handleUpdateEvent()
}

/**
 * fills in the carousel with product tile html objects
 * @param {string} einsteinResponse string html for product tiles
 * @param {jQuery} $parentElement parent element where recommendations will show.
 */
function fillDomElement(einsteinResponse, $parentElement) {
    var recommender = $parentElement.data('recommender');
    var recommendedProducts = einsteinResponse[recommender].recs;
    if (recommendedProducts && recommendedProducts.length > 0) {
        var template = $parentElement.data('template');
        var components = [];
        components = recommendedProducts.map(function (recommendedProduct) {
            var tiledefinition = {};
            tiledefinition.template = template;
            tiledefinition.model = {
                type: 'product',
                id: recommendedProduct.id
            };
            return tiledefinition;
        });

        var url = new URL($parentElement.data('product-load-url'));
        url.searchParams.append('components', JSON.stringify(components));
        url.searchParams.append('limit', $parentElement.data('limit'));
        url.searchParams.append('recommender', recommender);
        $.ajax({
            url: url.href,
            type: 'get',
            dataType: 'html',
            success: function (html) {
                // Prepare HTML before injecting into DOM
                const fragment = document.createDocumentFragment();
                $(fragment).html(html);
                // Inject script tags
                $(fragment).find('script').detach().appendTo($parentElement);
                // Inject slides
                $parentElement.find('.carousel-inner').html(fragment);
                createControls($parentElement);
                initializeCarousel($parentElement);
            },
            error: function () {
                $parentElement.spinner().stop();
            }
        });
    }
}

/**
 * Processes a recommendation tile, with an already initialized category specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @param {Object} einsteinUtils cquotient object
 * @param {Array} anchorsArray array of objects representing anchors
 */
function processRecommendationsTile($parentElement, einsteinUtils, anchorsArray) {
    var recommender = $parentElement.data('recommender');

    var params = {
        userId: einsteinUtils.getCQUserId(),
        cookieId: einsteinUtils.getCQCookieId(),
        ccver: '1.01'
    };

    if (anchorsArray) {
        params.anchors = anchorsArray;
    }

    /**
    * Processes a recommendation responses
    * @param {Object} einsteinResponse cquotient object
    */
    function recommendationsReceived(einsteinResponse) {
        fillDomElement(einsteinResponse, $parentElement);
        $parentElement.spinner().stop();
    }

    if (einsteinUtils.getRecs) {
        einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, recommendationsReceived);
    } else {
        einsteinUtils.widgets = einsteinUtils.widgets || []; // eslint-disable-line no-param-reassign
        einsteinUtils.widgets.push({
            recommenderName: recommender,
            parameters: params,
            callback: recommendationsReceived
        });
    }
}

/**
 * Processes a recommendation tile, with an already initialized product specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createProductAnchor($parentElement) {
    return [{
        id: $parentElement.data('primaryProductId'),
        sku: $parentElement.data('secondaryProductId'),
        type: $parentElement.data('alternativeGroupType'),
        alt_id: $parentElement.data('alternativeGroupId')
    }];
}

/**
 * Rerieves data attributes from parent element and converts to gretel compatible recommenders array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createCategoryAnchor($parentElement) {
    return [{ id: $parentElement.data('categoryId') }];
}

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 */
function loadRecommendations() {
    var einsteinUtils = getEinsteinUtils();
    if (einsteinUtils) {
        var $recommendationTiles = $('.einstein-carousel');
        $recommendationTiles.each(function () {
            var $parentElement = $(this);
            $parentElement.spinner().start();
            if ($(this).closest('.experience-einstein-einsteinCarouselProduct').length) {
                return processRecommendationsTile($parentElement, einsteinUtils, createProductAnchor($parentElement));
            } if ($(this).closest('.experience-einstein-einsteinCarouselCategory').length) {
                return processRecommendationsTile($parentElement, einsteinUtils, createCategoryAnchor($parentElement));
            }
            return processRecommendationsTile($parentElement, einsteinUtils);
        });
    }
}

$(document).ready(function () {
    loadRecommendations();
});
